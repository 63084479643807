import * as React from "react"
import Herobgimage from "../images/herobg.jpeg"

import imageone from "../images/jpg/kitchen.jpg"

import cta_bg from "../images/jpg/footer-top-bg.jpg"
import image1 from "../images/tall-wardrobes/tall-wardrobes-1.jpg"
import image2 from "../images/tall-wardrobes/tall-wardrobes-2.jpg"
import image3 from "../images/tall-wardrobes/tall-wardrobes-3.jpg"
import image4 from "../images/tall-wardrobes/tall-wardrobes-4.jpg"
import image5 from "../images/tall-wardrobes/tall-wardrobes-5.jpg"
import image6 from "../images/tall-wardrobes/tall-wardrobes-6.jpg"
import image7 from "../images/tall-wardrobes/tall-wardrobes-7.jpg"
import image8 from "../images/tall-wardrobes/tall-wardrobes-8.jpg"
import image9 from "../images/tall-wardrobes/tall-wardrobes-9.jpg"

import {
  Seo,
  Layout,
  HeroBanner,
  Twocolsec,
  Cta_section,
  ImageSlider,
} from "components"

const herodata = {
  bgimage: Herobgimage,
  title: " Complete solutions for residential and commercial Modular interiors",
  description: "You don’t have to pay a premium to get luxury",
  heading: "Needed a quotation for your project?",
  button: [
    {
      openInNewWindow: true,
      buttonTitle: "Get quote",
      phno: "https://wa.me/message/3SP5IKK67RSWO1",
      buttonUrl: "#",
    },
  ],
}

const Twocolumn = {
  imagePosition: "Left",
  imageone: image5,
  title: "Alpha Modular Installation & Renovation in Bangalore.",
  descrption1:
    "We provide Installation of Modular Kitchen, Modular Wardrobe, Modular TV Units, Storage Wall Units, False Ceiling, Woodwork, Renovation, Lighting , Wooden Flooring and Wall Decor.",
  descrption2:
    "We are proud to provide you the best quality product with outstanding finish that will make your house as home.",
  descrption3:
    "Our interior design and renovations experts can visit you in Bangalore and the surrounding area.",
  button: [
    {
      openInNewWindow: false,
      buttonTitle: "CALL NOW",
      phno: "tel:+917975344458",
      buttonUrl: "#",
    },
  ],
}

const cta_content = {
  title: "Get a Free Consultation ",
  description: "Let's Work Together",
  buttonTitle: "call us",
  buttonLink: "tel:+917975344458",
  background_image: { cta_bg },
}
const images = [
  {
    original: image1,
    ImageAlt: image1,
  },
  {
    original: image2,
    ImageAlt: image2,
  },
  {
    original: image3,
    ImageAlt: image3,
  },
  {
    original: image4,
    ImageAlt: image4,
  },
  {
    original: image5,
    ImageAlt: image5,
  },
  {
    original: image6,
    ImageAlt: image6,
  },
  {
    original: image7,
    ImageAlt: image7,
  },
  {
    original: image8,
    ImageAlt: image8,
  },
  {
    original: image9,
    ImageAlt: image9,
  },
]

const LuxuryTallWardrobes = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Luxury Tall Wardrobes" location={location?.pathname} />
      <HeroBanner data={herodata} />
      <Twocolsec data={Twocolumn} />
      <ImageSlider data={images} />
      <Cta_section data={cta_content} />
    </Layout>
  )
}

export default LuxuryTallWardrobes
